import { Navigate, Outlet } from "react-router-dom";
import { useZustandStore } from "./store/store";

const PrivateRoutes = () => {
  const { isSignedIn } = useZustandStore();

  return isSignedIn === true ? (
    <>
      <Outlet />
    </>
  ) : (
    <Navigate to="/login" />
  );
};

export default PrivateRoutes;
