import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TablePagination from "@mui/material/TablePagination";
import { useCallback } from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import AxiosClient from "../service/AxiosClient";

function getIndianTime(timestamp) {
  const date = new Date(timestamp);
  const options = {
    timeZone: "Asia/Kolkata", // Set timezone to Indian Standard Time
    hour12: false, // Use 24-hour format
    weekday: "long", // Display full weekday name
    year: "numeric", // Display full year
    month: "long", // Display full month name
    day: "numeric", // Display day of the month
    hour: "2-digit", // Display hours (00 to 23)
    minute: "2-digit", // Display minutes (00 to 59)
    second: "2-digit", // Display seconds (00 to 59)
  };

  return date.toLocaleString("en-IN", options);
}

const SessionsTable = () => {
  const { ip } = useParams();
  const [merchantData, setMerchantData] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [onlyPurchase, setonlyPurchase] = useState(false);

  const fetchData = useCallback(async () => {
    try {
      const response = await AxiosClient.get(`/sessions/${ip}`, {
        params: { page, pageSize, onlyPurchase },
      });
      const { data, totalItems } = response.data;
      setMerchantData(data);
      setTotalItems(totalItems);
    } catch (error) {
      console.log(error);
    }
  }, [page, pageSize, onlyPurchase, ip]);

  useEffect(() => {
    fetchData();
  }, [ip, page, pageSize, fetchData, onlyPurchase]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleChangePageSize = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(1);
  };

  const isPurchased = (row) => {
    return row.hasPurchased === true ? "Yes" : "No";
  };

  const returnCustomerDetails = (row) => {
    if (row.customerDetails) {
      return `${row.customerDetails.first_name} ${row.customerDetails.last_name}`;
    }
    return "No Customer Details Found";
  };

  const nav = useNavigate();
  return (
    <Box sx={{ width: "90%", margin: "auto", my: "20px" }}>
      <Box sx={{ display: "flex", mb: "10px" }}>
        <FormControlLabel
          control={
            <Checkbox
              onChange={(e) => {
                setonlyPurchase(e.target.checked);
              }}
            />
          }
          label="Show only Purchased"
        />
      </Box>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Ip Address</TableCell>
              <TableCell align="left">Merchant ID</TableCell>
              <TableCell align="left">Purchased</TableCell>
              <TableCell align="left">User Info</TableCell>
              <TableCell align="left">Created At</TableCell>
              <TableCell align="left">Updated At</TableCell>
              <TableCell align="left">Customer Details</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {merchantData?.map((row, index) => (
              <TableRow
                key={index}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  onClick={() => {
                    nav("/" + row._id);
                  }}
                  sx={{ cursor: "pointer" }}
                >
                  {row.ip}
                </TableCell>
                <TableCell align="left">{row.merchantId}</TableCell>
                <TableCell align="left">{isPurchased(row)}</TableCell>
                <TableCell align="left" sx={{ maxWidth: "100px" }}>
                  {row.userInfo.platform}
                </TableCell>
                <TableCell align="left">
                  {getIndianTime(row.createdAt)}
                </TableCell>
                <TableCell align="left">
                  {getIndianTime(row.updatedAt)}
                </TableCell>
                <TableCell align="left">{returnCustomerDetails(row)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50, 100]}
        component="div"
        count={totalItems}
        rowsPerPage={pageSize}
        page={page - 1}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangePageSize}
      />
    </Box>
  );
};

export default SessionsTable;
