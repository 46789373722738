import { Route, Routes, useNavigate } from "react-router-dom";
import MainPage from "./pages/MainPage";
import AllSessions from "./pages/AllSessions";
import MerchantSessions from "./pages/MerchantSessions";
import Password from "./pages/Password";
import { useEffect, useState } from "react";
import CustomerSessions from "./pages/CustomerSessions";
import MerchantCustomerTable from "./pages/MerchantCustomerTable";
import MainTable from "./pages/MainTable";
import SessionsTable from "./pages/SessionsTable";
import { Box, CircularProgress } from "@mui/material";
import AxiosClient from "./service/AxiosClient";
import PrivateRoutes from "./PrivateRoutes";
import { useZustandStore } from "./store/store";

const App = () => {
  const nav = useNavigate();
  const { isSignedIn, setIsSignedIn } = useZustandStore();
  const [loading, setLoading] = useState(true);

  const getAdmin = async () => {
    try {
      const { data } = await AxiosClient.get("getAdmin");
      if (data?._id) {
        setIsSignedIn(true);
      }
      nav("/table");
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAdmin();
  }, []);

  if (loading) {
    return (
      <Box
        sx={{
          minHeight: "90vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }
  return (
    <Routes>
      <Route element={<PrivateRoutes />}>
        <Route path="/sessions/:ip" element={<SessionsTable />} />
        <Route path="/" element={<MainTable />} />
        <Route path="/table" element={<MainTable />} />
        <Route path="/merchants/:id" element={<MerchantCustomerTable />} />
        <Route path="/merchants" element={<MerchantSessions />} />
        <Route path="/sessions/:id" element={<AllSessions />} />
        <Route path="/:id" element={<MainPage />} />
        <Route
          path="/customer/sessions/:merchantId/:customerId"
          element={<CustomerSessions />}
        />
      </Route>
      <Route path="/login" element={<Password />} />
    </Routes>
  );
};

export default App;
