import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Box } from "@mui/material";

const AllSessions = () => {
  const [sessionsData, setSessionsData] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const { id } = useParams();
  const nav = useNavigate();

  const fetchAllSessions = useCallback(async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/merchant/sessions/${id}`
      );
      setSessionsData(data);
    } catch (error) {
      console.log(error);
    }
  }, [id]);

  function getIndianTime(timestamp) {
    const date = new Date(timestamp);
    const options = {
      timeZone: "Asia/Kolkata",
      hour12: false,
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    };

    return date.toLocaleString("en-IN", options);
  }

  useEffect(() => {
    fetchAllSessions();
  }, [fetchAllSessions]);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredSessions = sessionsData
    ? sessionsData.filter((item) =>
        item.ip.toLowerCase().includes(searchTerm.toLowerCase())
      )
    : [];

  return (
    <Box sx={{ width: "90%", margin: "20px auto 0" }}>
      <h2 sx={{ marginBottom: "10px" }}>All Sessions</h2>

      {/* Search Input */}
      <input
        type="text"
        placeholder="Search by IP Address"
        style={{
          width: "100%",
          padding: "10px",
          borderRadius: "8px",
          border: "1px solid #ddd",
          marginBottom: "10px",
        }}
        value={searchTerm}
        onChange={handleSearch}
      />

      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))",
          gap: "10px",
          justifyContent: "flex-start",
        }}
      >
        {filteredSessions.map((item, index) => (
          <Box
            key={index}
            sx={{
              border: "1px solid #00000024",
              borderRadius: "8px",
              padding: "8px",
              cursor: "pointer",
            }}
            onClick={() => {
              nav("/" + item._id);
            }}
          >
            <p>IP: {item.ip}</p>
            <p>Created At: {getIndianTime(item.createdAt)}</p>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default AllSessions;
