import axios from "axios";
import React, { useCallback, useEffect } from "react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

const CustomerSessions = () => {
  const [sessionsData, setSessionsData] = useState(null);
  const { merchantId, customerId } = useParams();

  const fetchAllSessions = useCallback(async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/customer/sessions/${merchantId}/${customerId}`
      );
      setSessionsData(data);
    } catch (error) {
      console.log(error);
    }
  }, [merchantId, customerId]);

  function getIndianTime(timestamp) {
    const date = new Date(timestamp);
    const options = {
      timeZone: "Asia/Kolkata", // Set timezone to Indian Standard Time
      hour12: false, // Use 24-hour format
      weekday: "long", // Display full weekday name
      year: "numeric", // Display full year
      month: "long", // Display full month name
      day: "numeric", // Display day of the month
      hour: "2-digit", // Display hours (00 to 23)
      minute: "2-digit", // Display minutes (00 to 59)
      second: "2-digit", // Display seconds (00 to 59)
    };

    return date.toLocaleString("en-IN", options);
  }
  const nav = useNavigate();
  useEffect(() => {
    fetchAllSessions();
  }, [fetchAllSessions]);

  return (
    <div style={{ width: "90%", margin: "20px auto 0 " }}>
      <h2>All Sessions</h2>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: "10px",
          justifyContent: "flex-start",
          margin: "20px auto 0 ",
        }}
      >
        {sessionsData?.map((item, index) => (
          <div
            key={index}
            style={{
              border: "1px solid #00000024",
              borderRadius: "8px",
              padding: "8px",
            }}
            onClick={() => {
              nav("/" + item._id);
            }}
          >
            <p>ip: {item.ip}</p>
            <p>created at: {getIndianTime(item.createdAt)}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CustomerSessions;
