import axios from "axios";
import { useCallback, useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import ReactJson from "react-json-view";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import ReactMarkdown from "react-markdown";
import CircularProgress from "@mui/material/CircularProgress";
import AxiosClient from "../service/AxiosClient";

const MainPage = () => {
  const { id } = useParams();
  const [rawData, setRawData] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await AxiosClient.get(`/${id}`);
      setRawData(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }, [id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const components = {
    p: ({ children }) => (
      <p
        style={{
          marginBottom: "10px",
          fontSize: "16px",
          lineHeight: "1.5",
          fontFamily: `"Roboto"`,
        }}
      >
        {children}
      </p>
    ),
    li: ({ children }) => (
      <li style={{ marginBottom: "8px", fontSize: "16ox" }}>{children}</li>
    ),
  };

  const [selectedTab, setSelectedTab] = useState(0);

  if (loading) {
    return (
      <Box
        sx={{
          minHeight: "90vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ width: "90%", margin: "25px auto" }}>
      <Typography variant="h4" gutterBottom>
        Single Session
      </Typography>

      {rawData?.customerDetails && (
        <Box sx={{ mb: 3 }}>
          <ReactJson
            src={rawData.customerDetails}
            theme="monokai"
            collapsed={1}
            displayDataTypes={false}
          />
        </Box>
      )}

      {rawData && (
        <Box sx={{ mb: 3 }}>
          <ReactJson
            src={rawData}
            theme="monokai"
            collapsed={1}
            displayDataTypes={false}
          />
        </Box>
      )}

      <Box
        sx={{
          display: "flex",
          gap: "10px",
          my: "20px",
        }}
      >
        <Button
          variant={selectedTab === 0 ? "contained" : "outlined"}
          onClick={() => {
            setSelectedTab(0);
          }}
        >
          Processed Data
        </Button>
        {rawData?.aiInterpretation === true && (
          <Button
            variant={selectedTab === 1 ? "contained" : "outlined"}
            onClick={() => {
              setSelectedTab(1);
            }}
          >
            AI Analysis
          </Button>
        )}
      </Box>
      {selectedTab === 0 ? (
        rawData?.processedData?.map((item, index) => (
          <Box
            key={index}
            sx={{
              p: 2,
              backgroundColor: "#f5f5f5",
              mb: 2,
              borderRadius: 4,
              wordBreak: "break-all",
            }}
          >
            {Array.isArray(item) ? (
              item.map((action, idx) => (
                <Typography key={idx} variant="body1" sx={{ mb: 1 }}>
                  {action}
                </Typography>
              ))
            ) : (
              <Typography variant="body1">{`${index + 1}: ${item}`}</Typography>
            )}
          </Box>
        ))
      ) : (
        <Box
          sx={{
            p: 2,
            backgroundColor: "#f5f5f5",
            mb: 2,
            borderRadius: 4,
            wordBreak: "break-all",
          }}
        >
          <ReactMarkdown components={components}>
            {rawData?.aiResponse}
          </ReactMarkdown>
        </Box>
      )}
    </Box>
  );
};
export default MainPage;
