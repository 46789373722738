import axios from "axios";

const AxiosClient = axios.create();

AxiosClient.defaults.baseURL = process.env.REACT_APP_BACKEND_URL;

AxiosClient.defaults.headers = {
  "Content-Type": "application/json",
};

AxiosClient.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("authToken");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
export default AxiosClient;
