import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TablePagination from "@mui/material/TablePagination";
import { useCallback } from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import AxiosClient from "../service/AxiosClient";

function getIndianTime(timestamp) {
  const date = new Date(timestamp);
  const options = {
    timeZone: "Asia/Kolkata", // Set timezone to Indian Standard Time
    hour12: false, // Use 24-hour format
    weekday: "long", // Display full weekday name
    year: "numeric", // Display full year
    month: "long", // Display full month name
    day: "numeric", // Display day of the month
    hour: "2-digit", // Display hours (00 to 23)
    minute: "2-digit", // Display minutes (00 to 59)
    second: "2-digit", // Display seconds (00 to 59)
  };

  return date.toLocaleString("en-IN", options);
}

const MainTable = () => {
  const { id } = useParams();
  const [merchantData, setMerchantData] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [customerData, setCustomerData] = useState([]);
  const [onlyPurchase, setonlyPurchase] = useState(false);
  const [merchantIds, setMerchantIds] = useState([]);
  const [selectedId, setSelectedId] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(true);

  const fetchData = useCallback(async () => {
    try {
      const response = await AxiosClient.get(`/allmerchants`, {
        params: {
          page,
          pageSize,
          onlyPurchase,
          merchantId: selectedId,
          searchTerm,
        },
      });
      const { session, customers, totalItems } = response.data;
      setMerchantData(session);
      setTotalItems(totalItems);
      setCustomerData(customers);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }, [page, pageSize, onlyPurchase, selectedId, searchTerm]);

  useEffect(() => {
    fetchData();
  }, [id, page, pageSize, fetchData, onlyPurchase, selectedId, searchTerm]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  useEffect(() => {
    setLoading(true);
  }, [pageSize, selectedId, onlyPurchase]);

  const handleChangePageSize = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(1);
  };

  const fetchMerchants = async () => {
    try {
      const { data } = await AxiosClient.get(`/merchantIds`);
      setMerchantIds(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchMerchants();
  }, []);

  const isPurchased = (row) => {
    return row.hasPurchased === true ? "Yes" : "No";
  };

  const getUserSessions = (row) => {
    const customer = customerData.find((item) => item.ip === row.ip);
    return customer?.userSessions?.length;
  };
  const getDomain = (row) => {
    const customerDataa = customerData.find((item) => item.ip === row.ip);
    for (const sessions of customerDataa.userSessions) {
      if (sessions.sessionId === row.sessionId) {
        return sessions.url || "No Domain Found";
      }
    }
    return "No Domain Found";
  };

  const getCustomerData = (row) => {
    const customer = customerData.find((item) => item.ip === row.ip);
    if (customer.customerDetails) {
      return `${customer?.customerDetails?.first_name} ${customer?.customerDetails?.last_name}`;
    }
    return "No Customer Details Found";
  };

  const nav = useNavigate();

  return (
    <Box sx={{ width: "90%", margin: "auto", my: "20px" }}>
      <Box sx={{ display: "flex", mb: "10px" }}>
        <FormControlLabel
          control={
            <Checkbox
              onChange={(e) => {
                setonlyPurchase(e.target.checked);
              }}
            />
          }
          label="Show only Purchased"
        />
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">
            Selected Merchant
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selectedId}
            label="Selected Merchant"
            onChange={(e) => {
              setPage(1);
              setSelectedId(e.target.value);
            }}
          >
            {merchantIds?.map((item, index) => (
              <MenuItem key={index} value={item.merchantId}>
                {item.merchantId}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          label="Search by IP Address"
          variant="outlined"
          value={searchTerm}
          onChange={(e) => {
            setSearchTerm(e.target.value);
          }}
          sx={{ marginLeft: "20px" }}
        />
      </Box>
      {!loading ? (
        <Box>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Ip Address</TableCell>
                  <TableCell align="left">Merchant ID</TableCell>
                  <TableCell align="left">User Sessions</TableCell>
                  <TableCell align="left">Purchased</TableCell>
                  <TableCell align="left">User Info</TableCell>
                  <TableCell align="left">Created At</TableCell>
                  <TableCell align="left">Updated At</TableCell>
                  <TableCell align="left">Customer Details</TableCell>
                  <TableCell align="left">Domain</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {merchantData?.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{ cursor: "pointer" }}
                      onClick={() => {
                        nav(`/sessions/${row.ip}`);
                      }}
                    >
                      {row.ip}
                    </TableCell>
                    <TableCell align="left">{row.merchantId}</TableCell>
                    <TableCell align="left">{getUserSessions(row)}</TableCell>
                    <TableCell align="left">{isPurchased(row)}</TableCell>
                    <TableCell align="left" sx={{ maxWidth: "100px" }}>
                      {row.userInfo.platform}
                    </TableCell>
                    <TableCell align="left">
                      {getIndianTime(row.createdAt)}
                    </TableCell>
                    <TableCell align="left">
                      {getIndianTime(row.updatedAt)}
                    </TableCell>
                    <TableCell align="left">{getCustomerData(row)}</TableCell>
                    <TableCell align="left">{getDomain(row)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            component="div"
            count={totalItems}
            rowsPerPage={pageSize}
            page={page - 1}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangePageSize}
          />
        </Box>
      ) : (
        <Box
          sx={{
            minHeight: "90vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
};

export default MainTable;
