import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const MerchantSessions = () => {
  const [merchantData, setMerchantData] = useState([]);
  const [searchTerms, setSearchTerms] = useState({}); // Object to manage searchTerm for each merchant
  const navigate = useNavigate();

  const fetchAllSessions = useCallback(async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/merchants`
      );
      setMerchantData(data);
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    fetchAllSessions();
  }, [fetchAllSessions]);

  const navigateToMerchant = (merchantId) => {
    navigate(`/merchants/${merchantId}`);
  };

  const navigateToCustomerSession = (merchantId, customerId) => {
    navigate(`/customer/sessions/${merchantId}/${customerId}`);
  };

  const handleSearchTermChange = (merchantId, value) => {
    setSearchTerms((prevSearchTerms) => ({
      ...prevSearchTerms,
      [merchantId]: value,
    }));
  };

  const filteredCustomerIds = (customerIds, searchTerm) => {
    if (!searchTerm) return customerIds;
    return customerIds.filter((id) =>
      id.toLowerCase().includes(searchTerm.toLowerCase())
    );
  };

  return (
    <div style={{ width: "90%", margin: "20px auto 0" }}>
      <h2 style={{ textAlign: "center", marginBottom: "20px" }}>Merchants</h2>

      <div
        style={{ display: "grid", gap: "20px", gridTemplateColumns: "1fr 1fr" }}
      >
        {merchantData.map((item, index) => (
          <div key={index} style={merchantContainerStyle}>
            <div style={merchantHeaderStyle}>
              <h2
                style={{ marginBottom: "10px" }}
                onClick={() => navigateToMerchant(item.merchantId)}
              >
                Merchant ID: {item.merchantId}
              </h2>
              <input
                type="text"
                placeholder="Search Customer ID"
                style={{
                  width: "100%",
                  padding: "10px",
                  marginTop: "20px",
                  borderRadius: "8px",
                  border: "1px solid #ddd",
                }}
                value={searchTerms[item.merchantId] || ""}
                onChange={(e) =>
                  handleSearchTermChange(item.merchantId, e.target.value)
                }
              />
            </div>
            <div style={customerIdsContainerStyle}>
              {filteredCustomerIds(
                item.customerIds || [],
                searchTerms[item.merchantId] || ""
              )
                .reverse()
                .map((id, idx) => (
                  <div
                    key={idx}
                    style={customerIdStyle}
                    onClick={() =>
                      navigateToCustomerSession(item.merchantId, id)
                    }
                  >
                    {id}
                  </div>
                ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

// Styles
const merchantContainerStyle = {
  border: "1px solid #ddd",
  borderRadius: "8px",
  padding: "20px",
  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
};

const merchantHeaderStyle = {
  fontSize: "18px",
  fontWeight: "bold",
  cursor: "pointer",
};

const customerIdsContainerStyle = {
  marginTop: "10px",
  display: "flex",
  flexWrap: "wrap",
};

const customerIdStyle = {
  border: "1px solid #ccc",
  borderRadius: "5px",
  padding: "8px",
  marginRight: "10px",
  marginBottom: "10px",
  cursor: "pointer",
};

export default MerchantSessions;
